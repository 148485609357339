<template>
  <div :class="[ 'flex flex-col-reverse lg:flex-row rounded-none lg:rounded-b-panel z-2', 'h-fit lg:h-57.5', link ? 'cursor-pointer' : '' ]">
    <div :class="[ 'w-full lg:w-137.5 h-full flex-shrink-0 bg-menu-dark lg:bg-primary-dark p-5 lg:p-13 rounded-b-panel lg:rounded-none lg:rounded-bl-panel text-light', imgSize.size === 'imageLarge' ? 'carousel-radial-gradient' : 'carousel-radial-gradient' ]">
      <Transition name="fade" mode="out-in">
        <div v-if="heading" class="w-full h-full flex flex-col items-center lg:items-start justify-center" @click="handleClick">
          <div :class="[ 'mb-0 lg:mb-4 font-bold text-heading-accent lg:font-normal text-center lg:text-left hyphens-none', imgSize.size === 'imageSmall' ? 'text-2xl' : '', imgSize.size === 'imageMedium' ? 'text-3xl' : '', imgSize.size === 'imageLarge' ? 'text-4xl' : '' ]" v-html="heading" />
          <p :class="[ 'font-normal text-sub-heading-accent text-center sm:text-left hyphens-none', imgSize.size === 'imageSmall' ? 'text-base' : '', imgSize.size === 'imageMedium' ? 'text-large' : '', imgSize.size === 'imageLarge' ? 'text-xl' : '' ]" v-html="subheading" />
        </div>
      </Transition>
    </div>
    <div :class="[ 'flex w-full overflow-hidden z-1 lg:rounded-none lg:rounded-br-panel bg-primary-dark', imgSize.size === 'imageLarge' ? 'h-57.5' : 'h-auto' ]">
      <Swiper
        ref="bannerGallery"
        :space-between="0"
        :slides-per-view="1"
        :loop="true"
        :centered-slides="true"
        :pagination="{
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: (index, className) => renderBullet(index, className)
        }"
        :speed="2000"
        class="w-full"
        :modules="gallery.length > 1 ? [Autoplay, Pagination] : []"
        @click="handleClick"
        @swiper="onSwiper"
        @slide-change="onSlideChange"
      >
        <SwiperSlide
          v-for="(item, idx) in gallery"
          :key="`banner-gallery-${idx}`"
          class="w-full"
        >
          <div v-if="imgSrc" :class="[ 'relative w-full overflow-hidden flex justify-center items-center', imgSize.size === 'imageLarge' ? 'h-57.5' : 'h-auto' ]">
            <div :class="[ 'w-full h-full flex items-center justify-center', imgSize.size === 'imageLarge' ? 'absolute' : '' ]" style="left: -1px; right: -1px; width: calc(100% + 2px);">
              <NuxtImg
                :src="item[imgSize.size]"
                format="webp"
                :alt="heading"
                :width="imgSize.width"
                :height="imgSize.height"
                :sizes="`320px 768px ${imgSize.width}px`"
                fit="cover"
                :modifiers="{ rotate: null }"
                :class="[ 'w-full h-full', imgSize.size === 'imageLarge' ? 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover' : '' ]"
                :style="imgSize.size === 'imageLarge' ? `width: ${imgSize.width}px; height: ${imgSize.height}px;` : `width: 100vw; height: auto;`"
              />
            </div>
          </div>
        </SwiperSlide>
        <div class="swiper-pagination"/>
      </Swiper>
    </div>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { Autoplay, Pagination } from 'swiper/modules';
import { getKeyValue } from "@utils/get-key-value";
import { sitePages } from "@settings/site-pages.settings";
import { useEnumStore } from '@stores/enum';
import { useConfigStore } from '@stores/config';

const props = defineProps({
  type: {
    type: String,
    required: true
  }
});

const { t } = useI18n();
const enums = useEnumStore();
const config = useRuntimeConfig();
const getPageData = getKeyValue(sitePages, config.public.layoutDesign);
const siteBanners = _.map(getPageData[props.type], banner => ({
  type: props.type,
  imageSmall: banner.image,
  imageMedium: banner.image,
  imageLarge: banner.image,
  title: t(banner.heading),
  content: t(banner.subheading),
  url: banner?.routeCode ? translatePath(banner.routeCode) : null
}));

const loaded = ref(false);
const gallery = ref(siteBanners);

const windowSize = useWindowSize();
const slideIndex = ref(-1);
const heading = ref('');
const subheading = ref('');
const link = ref('');
const imgSrc = ref('');
const bannerSwiper = ref(null);
const bannerGallery = ref(null);
const imgSize = ref({ width: 320, height: 84, size: 'imageSmall' });
const configs = useConfigStore();

const updateImgSize = (value) => {
  if (import.meta.client) {
    if (value >= 1024) {
      imgSize.value = { width: ((value - 550) * 500 / 1500 < 230) ? 690 : (value - 550), height: ((value - 550) * 500 / 1500 < 230) ? 230 : ((value - 550) * 500 / 1500), size: 'imageLarge' };
    } else if (value >= 768) {
      imgSize.value = { width: 768, height: 202, size: 'imageMedium' };
    } else {
      imgSize.value = { width: 320, height: 84, size: 'imageSmall' };
    }
  } else {
    imgSize.value = { width: 320, height: 84, size: 'imageSmall' };
  }
}

watch(() => windowSize?.width?.value, (value) => updateImgSize(value));

const renderBullet = (index, className) => {
  return `<span class="${className}">&nbsp;</span>`;
};

const onSlideChange = (swiper) => {
  if (!loaded.value) {
    return;
  }
  if (slideIndex.value >= 0) {
    heading.value = '';
    subheading.value = '';
  }
  slideIndex.value = swiper.realIndex;
  nextTick(() => {
    heading.value = gallery.value[slideIndex.value]?.title || '';
    subheading.value = gallery.value[slideIndex.value]?.content || '';
    link.value = gallery.value[slideIndex.value]?.url || '';
    imgSrc.value = gallery.value[slideIndex.value][imgSize.value.size] || '';
  });
};

const handleClick = async () => {
  if (link.value) {
    await navigatorTo(link.value, { external: !_.startsWith(link.value, '/') });
  }
}

const setupAutoplay = (swiper, displaySpeed) => {
  if (swiper && swiper.params && gallery.value.length && displaySpeed) {
    swiper.params.autoplay = {
      delay: +displaySpeed,
      disableOnInteraction: false,
    };
    if (swiper.autoplay) {
      swiper.autoplay.start();
    }
  }
}

const onSwiper = (swiper) => {
  const displaySpeed = configs.displaySpeed || 5000;
  bannerSwiper.value = swiper;
  setupAutoplay(bannerSwiper.value, displaySpeed);
};

const setupVariables = (slides) => {
  nextTick(() => {
    heading.value = _.get(slides, '0.title', '');
    subheading.value = _.get(slides, '0.content', '');
    link.value = _.get(slides, '0.url', '');
    imgSrc.value = _.get(slides, `0.${imgSize.value.size}`, '');
  });
}

const setupBanners = (banners = []) => {
  const slides = _.filter(banners || [], banner => _.includes([props.type, 'all'], banner?.type));
  if (!_.isEmpty(slides)) {
    gallery.value = slides;
  } else {
    setupVariables(gallery.value);
  }
}

watch(() => enums.banners, setupBanners);

watch(() => gallery.value, setupVariables);

onMounted(() => {
  heading.value = '';
  subheading.value = '';
  nextTick(() => {
    windowSize.updateSize();
    updateImgSize(windowSize?.width?.value);
    setupBanners(enums.banners || []);
    loaded.value = true;
  });

});

</script>

<style scoped>
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}
</style>

<style>
.swiper-pagination-bullet {
  background: #F1F2EB;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 0 0.3rem;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #A4FB7B;
}
</style>
